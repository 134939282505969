<template>
  <div>
    <div class="contentBox">
      <div class="contentBoxLeft">
        <div class="searchBox">
          <el-input v-model="searchParams.firstname" placeholder="请输入关键词查找" class="ipt-default" size="small"></el-input>
          <el-button @click="GET_activeTypeGet(0)" type="primary" class="btn-default" style="margin-left:40px;"
            size="small">搜索
          </el-button>
        </div>
        <div class="mainBox">
          <el-button @click="add('first')" type="primary" class="addBox">+ 添加一级分类
          </el-button>
          <div class="listBox">
            <ul>
              <li v-for="(item, index) in typeList" :key="index" :class="chooseTypeFirst === index ? 'act' : ''"
                @click="listClick('firstClick', index, item)" style="padding-right:55px;">
                <div>{{ item.name }}</div>
                <div>佣金比例：{{ item.commission_rate }}%</div>
                <img src="/img/sctp@2x.png" @click.stop="del(item.category_id, 1)" />
                <span @click.stop="listClick('firstEdit', index, item)"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="contentBoxRight">
        <div class="searchBox">
          <el-input v-model="searchParams.secondname" placeholder="请输入关键词查找" class="ipt-default"
            size="small"></el-input>
          <el-button @click="GET_activeTypeGet(0)" type="primary" class="btn-default" style="margin-left:40px;"
            size="small">搜索
          </el-button>
        </div>
        <div class="mainBox">
          <el-button @click="add('second')" type="primary" class="addBox">+ 添加二级分类
          </el-button>
          <div class="listBox">
            <ul v-if="typeList.length > 0">
              <li v-for="(item, index) in typeList[chooseTypeFirst].children" :key="index"
                @click="listClick('second', index, item)">
                <div>{{ item.name }}</div>
                <div>佣金比例：{{ item.commission_rate }}%</div>
                <img src="/img/sctp@2x.png" @click.stop="del(item.category_id, 2)" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="35%" center>
      <div class="dialogContent" v-if="dialogTitle !== '删除分类'">
        <div class="left">
          <div>分类名称：</div>
          <div>排序：</div>
          <div>分类类型：</div>
          <div>佣金比例：</div>
          <div>分类图片：</div>
        </div>
        <div class="right">
          <div class="box">
            <el-input v-model="dialogParams.name" placeholder="请输入分类名称" class="ipt-default"></el-input>
          </div>
          <div class="box">
            <el-input v-model="dialogParams.category_order" placeholder="请输入排序" class="ipt-default"
              style="width:140px;"></el-input>
          </div>
          <div class="box">
            <el-select v-model="dialogParams.category_class" placeholder="请选择">
              <el-option v-for="item in categoryList" :key="item.type" :label="item.label"
                :value="item.type"></el-option>
            </el-select>
          </div>
          <div class="box">
            <el-input v-model="dialogParams.commission_rate" placeholder="请输入佣金比例" class="ipt-default"
              style="width:140px;"></el-input>
            <span>%</span>
          </div>
          <div class="box">
            <el-upload :action="MixinUploadApi" :show-file-list="false" :on-success="imgUploadSuccess"
              :before-upload="imgUploadBefore">
              <img v-if="dialogParams.image" :src="dialogParams.image" class="img">
              <div v-else style="display:flex;">
                <el-button size="small" type="primary">点击上传</el-button>
                <div style="color:#999;font-size:12px;line-height:30px;margin-left:10px;">建议上传jpg/png文件,且不超过1MB</div>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="dialogDelContent" v-else>删除分类将无法找回，确认删除吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">
          取 消
        </el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityType from "@/api/activityType";

export default {
  name: "activityList",
  data () {
    return {
      //  弹出框
      centerDialogVisible: false,
      dialogTitle: '',
      currentType: 0,
      dialogParams: {
        parent_id: '',
        name: '',
        category_order: '',
        commission_rate: '',
        image: '',
        category_class: 2, // 0:商品分类 1:课程分类 2:活动分类 3:资讯分类 4.学院与课程
      },
      categoryList: [
        // { label: '商品分类', type: 0 },
        // { label: '课程分类', type: 1 },
        { label: '活动分类', type: 2 },
        { label: '资讯分类', type: 3 },
        // { label: '学院与课程', type: 4 },
      ],

      //  搜索框
      searchParams: {
        firstname: '',
        secondname: ''
      },

      //  分类列表
      typeList: [],

      //  选中的一级分类序号
      chooseTypeFirst: 0,

      //  选中的分类id
      chooseTypeId: '',
      parent_id_cache: '',
    };
  },
  mounted () {
    this.GET_activeTypeGet(0);
    API_activityType.activeTypeGet(0, this.searchParams).then(res => {
      if (res.length > 0) {
        this.parent_id_cache = res[0].category_id
        this.chooseTypeId = res[0].category_id
      }
    });
  },
  methods: {
    //  搜索框取消按钮
    cancel () {
      this.centerDialogVisible = false;
      this.dialogParams = {
        parent_id: '',
        name: '',
        category_order: '',
        commission_rate: '',
        image: '',
        category_class: 2,
      }
    },
    //  弹出框确定按钮
    ok () {
      if (this.dialogTitle === '删除分类') {
        this.PUT_activeTypeDel(this.chooseTypeId);
      } else {
        if (this.dialogParams.name === '') {
          this.$message.error("请输入分类名称");
          return false;
        }
        if (this.dialogParams.category_order === '') {
          this.$message.error("请输入排序");
          return false;
        }
        if (this.dialogParams.commission_rate === '') {
          this.$message.error("请输入佣金比例");
          return false;
        }
        if (this.dialogParams.image === '') {
          this.$message.error("请上传分类图片");
          return false;
        }
        if (this.dialogTitle.indexOf('新增') > -1) {
          this.POST_activeTypeAdd();
        } else if (this.dialogTitle.indexOf('修改') > -1) {
          this.PUT_activeTypeEdit();
        }
      }
    },

    //  添加一级或二级分类
    POST_activeTypeAdd () {
      // console.log(this.dialogParams);
      API_activityType.activeTypeAdd(this.dialogParams).then(res => {
        console.log(res);
        this.$message.success("添加成功");
        this.dialogParams.parent_id = '';
        this.dialogParams.name = '';
        this.dialogParams.category_order = '';
        this.dialogParams.commission_rate = '';
        this.dialogParams.image = '';
        this.centerDialogVisible = false;
        this.GET_activeTypeGet(0);
      });
    },

    //  查询分类列表
    GET_activeTypeGet (id) {
      API_activityType.activeTypeGet(id, this.searchParams).then(res => {
        this.typeList = res;
      });
    },

    //  删除分类列表
    PUT_activeTypeDel (id) {
      API_activityType.activeTypeDel(id, this.currentType).then(res => {
        // console.log(res)
        this.chooseTypeId = '';
        this.centerDialogVisible = false;
        this.GET_activeTypeGet(0);
      });
    },

    //  修改分类列表
    PUT_activeTypeEdit () {
      API_activityType.activeTypeEdit(this.dialogParams, this.chooseTypeId).then(res => {
        // console.log(res);
        this.$message.success("修改成功");
        // this.dialogParams.parent_id = '';
        this.dialogParams.name = '';
        this.dialogParams.category_order = '';
        this.dialogParams.commission_rate = '';
        this.dialogParams.image = '';
        this.centerDialogVisible = false;
        this.GET_activeTypeGet(0);
      });
    },

    //  列表点击事件
    listClick (type, index, row) {
      if (type === 'firstClick') {
        this.chooseTypeFirst = index;
        this.dialogParams.parent_id = row.category_id;
        this.parent_id_cache = row.category_id;
      } else if (type === 'firstEdit') {
        this.dialogTitle = '修改一级分类';
        this.chooseTypeId = row.category_id;
        // this.dialogParams.parent_id = row.parent_id;
        this.dialogParams.parent_id = 0;
        this.dialogParams.name = row.name;
        this.dialogParams.category_order = row.category_order;
        this.dialogParams.commission_rate = row.commission_rate;
        this.dialogParams.image = row.image;
        this.centerDialogVisible = true;
      } else if (type === 'second') {
        this.dialogTitle = '修改二级分类';
        this.chooseTypeId = row.category_id;
        this.dialogParams.parent_id = this.parent_id_cache;
        this.dialogParams.name = row.name;
        this.dialogParams.category_order = row.category_order;
        this.dialogParams.commission_rate = row.commission_rate;
        this.dialogParams.image = row.image;
        this.centerDialogVisible = true;
      }
    },

    //  新增列表
    add (type) {
      if (type === 'first') {
        this.dialogTitle = '新增一级分类';
        this.dialogParams.parent_id = 0;
      } else if (type === 'second') {
        this.dialogTitle = '新增二级分类';
        this.dialogParams.parent_id = this.parent_id_cache;
      }
      this.dialogParams.name = '';
      this.dialogParams.category_order = '';
      this.dialogParams.commission_rate = '';
      this.dialogParams.image = '';
      this.centerDialogVisible = true;
    },

    //  图片上传前
    imgUploadBefore (file) {
      const isImg = file.type.indexOf("image") > -1;
      if (!isImg) {
        this.$message.error("只能上传图片");
      }
      return isImg;
    },

    //  图片上传成功
    imgUploadSuccess (res, file) {
      console.log(file)
      this.dialogParams.image = file.response.url;
    },

    //  删除分类
    del (id, type) {
      this.dialogTitle = "删除分类";
      this.chooseTypeId = id;
      this.centerDialogVisible = true;
      this.currentType = type;
    }
  }
};
</script>

<style type="text/scss" lang="scss" scoped>
.contentBox {
  padding: 36px;
  background: #fff;
  display: flex;
  justify-content: center;
  border-radius: 12px;

  .contentBoxLeft,
  .contentBoxRight {
    width: 400px;
    margin-right: 40px;
  }

  .searchBox {
    display: flex;
    height: 30px;
    margin-bottom: 10px;
  }

  .mainBox {
    border: 2px solid #ddd;

    .addBox {
      line-height: 30px;
      text-align: center;
      color: #008080;
      font-size: 18px;
      border: none;
      border-bottom: 2px solid #ddd;
      background: none;
      width: 100%;
      padding: 10px;
    }

    .listBox {
      height: 49vh;
      overflow-y: scroll;

      ul {
        padding: 0;
        margin: 0;

        li {
          width: 100%;
          padding: 10px 30px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #ddd;
          position: relative;
          cursor: pointer;

          div {
            line-height: 30px;
            color: #999;
            font-size: 16px;
          }

          img {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -8px;
            display: none;
          }

          span {
            color: rgba(255, 255, 255, 0.75);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            right: 30px;
            top: 50%;
            background-color: rgba(0, 0, 0, 0.4);
            margin-top: -8px;
            font-family: element-icons;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
            display: none;
          }

          span:before {
            content: "\E61C";
          }
        }

        li:hover {
          div {
            color: #008080;
          }

          img {
            display: block;
          }

          span {
            display: block;
          }
        }

        .act {
          background: #008080;

          div {
            color: #fff;
          }
        }

        .act:hover {
          div {
            color: #fff;
          }
        }
      }
    }
  }
}

.dialogContent {
  width: 100%;
  display: flex;

  .left {
    width: 100px;
    text-align: right;

    div {
      font-size: 16px;
      line-height: 41px;
      margin: 10px 0;
    }
  }

  .right {
    flex: 1;

    .box {
      min-height: 30px;
      margin: 10px 0;
      display: flex;

      span {
        display: block;
        line-height: 50px;
        margin-left: 5px;
      }

      img {
        width: 100px;
        height: auto;
      }
    }
  }
}

.dialogDelContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
</style>
